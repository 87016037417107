import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["button", "select"]

  connect() {
    this.changeSelection()
    $(this.element).on("select2:select", () => this.changeSelection())
  }

  changeSelection() {
    this.buttonTarget.setAttribute("href", this.selectTarget.value)
  }

  clickLink(e) {
    if (!this.buttonTarget.getAttribute("href")) e.preventDefault()
  }
}
